import Axios from 'axios';
import Swal from 'sweetalert2';
import { URL_API } from '@configs/keys';
import { getCompanySession, getToken, removeToken } from '@helpers/token';

const corpExClient = Axios.create({
  timeout: 10000
});

corpExClient.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = getToken();

  return config;
});

const corpReimburseClient = Axios.create({
  timeout: 30000
});

corpReimburseClient.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = getToken();
  config.headers['X-Channel'] = 'cust_mobile_app';

  return config;
});

const formRequestClient = Axios.create({
  baseURL: URL_API,
  headers: {
    'Content-Type': 'multipart/form-data',
    // TODO: Remove hardcoded value
    'X-Channel': 'cust_mobile_app'
  },
  timeout: 10000
});

formRequestClient.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = getToken();

  return config;
});

const httpRequest = Axios.create({
  baseURL: URL_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
});

httpRequest.interceptors.request.use(config => {
  const companySession = getCompanySession();
  // eslint-disable-next-line no-param-reassign
  config.headers['Company-Session'] = companySession?.id;

  return config;
});

httpRequest.interceptors.request.use(
  config => {
    const token = getToken();
    if (!config.baseURL.includes('login')) {
      if (token !== undefined) {
        config.headers.Authorization = token;
      }
    }

    return config;
  },
  error => {
    Promise.reject(error);
    throw error;
  }
);

httpRequest.interceptors.response.use(
  config => config,
  error => {
    if (
      error.response?.status == 401 ||
      error.response?.data == 'Invalid token\n'
    ) {
      Swal.fire('Session end', 'Please login again to continue', 'info');
      removeToken();
      setTimeout(() => {
        if (process.browser) {
          window.location.href = '/';
        }
      }, 1000);
    }
    Promise.reject(error);
    throw error;
  }
);

export { corpExClient, corpReimburseClient, formRequestClient };
export default httpRequest;
